
<template>
  <div>
    <div class="google-map" :id="mapName">
    </div>
  </div>

</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
export default {
  name: 'google-map',
  props: ['name'],
  data: function () {
    return {
      map: {},
      key: 'AIzaSyCa2YvsIlr5IsKSFw84opV-CWNsOMiwYhY',
      mapName: this.name + "-map",
      markers: [],
      circles: [],
      userLat: 51.501527,
      userLon: -0.1921837,
      currentLocation: '',
      googleStyle: [
        {
            "featureType": "landscape.natural",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#e0efef"
                }
            ]
        },



        {
            "featureType": "poi",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "hue": "#1900ff"
                },
                {
                    "color": "#c0e8e8"
                }
            ]
        },
        
          {
            "featureType": "poi.business",
            "stylers": [
                {
                    "visibility": "off"
                },
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "lightness": 100
                },
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "grey"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "lightness": 700
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#7dcdcd"
                }
            ]
        }
        
    ]
    }
  },
  computed: {
    ...mapGetters({

    })
  },
  watch: {

  },
  methods: {
    // showPosition : function() {
    //    return new Promise(function(resolve, reject) {
    //         let options = {
    //             enableHighAccuracy: false,
    //             timeout:    5000,   // time in millis when error callback will be invoked
    //                  // max cached age of gps data, also in millis
    //         };
    //         navigator.geolocation.getCurrentPosition(
    //         pos => { return resolve(pos); }, 
    //         err => { return reject (err); }, 
    //         options);
    //     })
    // },
    loadMap: function(drawLocation) {
    const element = document.getElementById(this.mapName)
    const options = {
      zoom: 13,
      center: new google.maps.LatLng(this.userLat, this.userLon),
      disableDefaultUI: true,
      zoomControl: true,
      styles: this.googleStyle,
    }
    var that = this
    this.map = new google.maps.Map(element, options);
    // if(drawLocation) {
    // let map = this.map
    // const position = new google.maps.LatLng(this.userLat, this.userLon)
    // const marker = new google.maps.Marker({position, map})
    // marker.setVisible(false)
    // let location = {lat: this.userLat, lon: this.userLon}
    // this.$store.dispatch('setUserLocation', location)
    

    // const circle = new google.maps.Marker({
    //     position: position,
    //     map: map,
    //     icon: icon,
    //     clickable: false,
    //     optimized: false,
    //     zIndex: -10
    // })
    
    google.maps.event.addListener(this.map, "click", function (event) {
      var latitude = event.latLng.lat();
      var longitude = event.latLng.lng();
      console.log(latitude, longitude)
      that.createMarker(latitude, longitude)
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode({
        latLng: new google.maps.LatLng(latitude, longitude)
      }, function(responses) {
        if (responses && responses.length > 0) {
          that.currentLocation = (responses[0].formatted_address)
          console.log(that.currentLocation)
          that.$store.commit('setCurrentPosition', {formatted: responses[0].formatted_address, position: {lat: latitude, long: longitude}})
        } else {
          console.log('Cannot determine address at this location.');
        }
      })
    })
    },
    createMarker: function(latitude, longitude) {
      var map = this.map
      var radius = this.radius
      // this.map.setZoom(17)
     
    // const icon = {
    // url: '/img/dot.png',
    // scaledSize: new google.maps.Size(60, 60)
    // }
    this.markers.forEach(marker => {marker.setMap(null)})
    const position = new google.maps.LatLng(latitude, longitude)
    const marker = new google.maps.Marker({
        position: position,
        map: map,
        // icon: icon,
        clickable: false,
        optimized: false,
        zIndex: -10
    })
      marker.optimized = false
      marker.zIndex = 999
      marker.setVisible(true)
      this.markers.push(marker)
    }
  },
  mounted: function () {
    // if (navigator.geolocation) {
    //     this.showPosition().then(position => {
    //         this.userLat = position.coords.latitude
    //         this.userLon = position.coords.longitude
    //         this.loadMap(true)
    //     }).catch((e) => {
    //         console.log(e)
    //         console.log('not retrieving location from browser')
    //         this.loadMap()
    //     })
    // }
    //  else {
    //     console.log('Geolocation is not supported by this browser')
    //     this.loadMap()
    // }
    this.loadMap()
  },
}
</script>

<style>
.google-map {
  width: 100%;
  height: calc(100vh - 57px);
  margin: 0 auto;
  margin-top: 10px;
  background: gray;
}
@media (max-width: 880px) {
  .google-map {
    height: 50vh!important;
  }
}
</style>