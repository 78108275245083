<template>
<div class="products">


  <div class="container">
    <div class="col-md-12 main">
      <h3 style="color: white;">Vulcan Coverage Overview</h3>
      <div class="section">
        <h2 class="sec-title">Weather Coverage <span class="alpha-label">alpha</span></h2>
        <div class="item col-md-4">
          <h2><i style="margin-right: 5px; margin-top: 15px;" class="fa fa-temperature-low"></i> Heating Degree Day (HDD) - Call</h2>
          <p class="desc">Heating Day Degree (HDD) calls are a way of hedging against cold weather. A HDD is the number of degrees that a day's average temperature is below 65 Fahrenheit (18 Celsius), which is the temperature below which buildings need to be heated.</p>
          <p class="acc">Accumulation Period: Day/Week/Month</p>
          <router-link to="newrequest" class="btn btn-primary" style="width: 100%; text-align: left;  margin-bottom: 5px;"><i class="fa-regular fa-globe"></i> Create Request</router-link><br>
        </div>
      </div>


       <!-- <div class="section" style="margin-top: 40px;">
        <h2 class="sec-title">Insurance Pools <span class="alpha-label">coming soon</span></h2>
        <div class="item col-md-4">
          <h2>Flood Protection</h2>
          <p class="desc">Floods are the most common natural disaster in the United States, floods can occur anywhere in the country at any time of year. Insure
            yourself or underwrite others.
          </p>
          <p class="acc">Contract Duration: 1 Year</p>
          <router-link to="" class="btn btn-primary disabled">Trade FLOOD</router-link>
        </div>
      </div> -->
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'header',
   data() {
    return {
      
    }
  },
  props: {
    msg: String
  },
  mounted() {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.breadcrumb {
  margin-left: 10%!important;
  color: white;
  font-weight: 600;
  font-size: 11px;
}

.alpha-label {
  background-color: #fa52a0;
  color: white;
  margin-left: 5px;
  padding: 3px 6px;
  margin-top: -2px;
  width: 100px;
  font-size: 14px;
  border-radius: 5px;
}

.btn-primary {
  font-weight: 600;
  background-color: #4015bd;
  border-radius: 12px;
  padding: 15px 20px;
  font-size: 14px;
  text-decoration: none;
  border: none;
  opacity: none;
  &.alt {
      background-color: #fa52a0;
  }
}
.item {
  float: left;
  margin-right: 20px;
  h2 {
    font-size: 15px;
    font-weight: 600;
    color: #14151d;
  }
  p.acc {
    font-weight: 600;
  }
  margin-top: 10px;
  background-color: #E7E7E7;
  border-radius: 14px;
  padding: 20px;
  font-size: 14px;
}
.main {
  padding-top: 100px;
  text-align: left;
}
.sec-title {
  margin-bottom: 20px;
  font-weight: 600;
  text-align: left;
  color: white;
  font-size: 18px;
  margin-top: 20px;
}
.products {
    min-height: 100vh;
    background-color: #15151d;
}
</style>
