import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'


// import HDDNYJUNE from "./components/HDD-NY-JUNE"
// import HDDAMSJUNE from "./components/HDD-AMS-JUNE"
import Products from "./components/Products"
// import GetWallet from "./components/GetWallet"
import Home from "./components/Home"
import Vuex from 'vuex'
import Dashboard from "./pages/Dashboard"
import MyDetailRequest from "./pages/MyDetailRequest"
import DetailRequest from "./pages/DetailRequest"
import InsuranceRequest from "./pages/InsuranceRequest"
import VueGoodTablePlugin from 'vue-good-table';

import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin);
Vue.use(Vuex)
Vue.use(VueRouter)

Vue.config.productionTip = false

const store = new Vuex.Store({
  state: {
    web3: null,
    wrongNetworkMessage: "",
    account: "",
    connectWallet: 0,
    currentPosition: {},
    loadingMessage: ""
  },
  mutations: {
    // set account
    setCurrentPosition(state, position) {
      state.currentPosition = position
    },
    connectWallet(state) {
      console.log('connecting wallet')
      state.connectWallet++;
    },
    setAccount(state, account) {
      state.account = account
    },
    setBalance(state, newBalance) {
        state.balance = newBalance
    },
    setLoadingMessage(state, loadingMessage) {
      state.loadingMessage = loadingMessage
    },
    // set web3
    async setWeb3(state, web3) {
      state.web3 = web3
      const checkNetwork = async (web3) => {
          let network = "optimism goerli"
          let con = await web3.eth.net.getId(); 
          console.log(con)
          if(con != 420)  {
              state.wrongNetworkMessage = `WARNING: You are connected to the wrong network. You are currently connected to the ${con} network but we require a connection to the ${network} network.`
          }
          else {
              if(state.wrongNetworkMessage != '') {
                // network is fixed
                // location.reload()
              }
              state.wrongNetworkMessage = ''
          }
      }
      // check network
      await checkNetwork(web3)
      let accounts = await web3.eth.getAccounts()
      state.accounts = accounts[0]

      window.ethereum.on('accountsChanged', async (accounts) => {
        state.account = accounts[0]
        console.log(accounts[0])
      })
      window.ethereum.on('networkChanged', async () => {
        await checkNetwork(web3)
      })
  },
  },
  getters: {
    getLoadingMessage (state) {
      return state.loadingMessage
    },
    getConnectWallet (state) {
      return state.connectWallet
    },
    // getweb3
    getWrongNetworkMessage (state) {
      return state.wrongNetworkMessage
    },
    getWeb3 (state) {
      return state.web3
    },
    getBalance(state) {
      return state.balance
    },
    getAccount(state) {
      return state.account
    },
    getProviderLoggedIn (state) {
      return state.providerEnabled
    },
    getCurrentPosition(state) {
      return state.currentPosition
    }
  },
  actions: {
    // get balance
    async getBalance({commit, getters}, account) {
      let web3 = getters.getWeb3;
      let balanceWei = await web3.eth.getBalance(account)
      let balanceEth = web3.utils.fromWei(balanceWei);
      return commit("setBalance", balanceEth)
    }
  }
})

const routes = [
  {path: '/', component: Home},
  {path: '/newrequest', component: InsuranceRequest},
  {path: '/dashboard', component: Dashboard},
  {path: '/products', component: Products},
  {path: '/myrequest/:address', component: MyDetailRequest},
  {path: '/request/:address', component: DetailRequest},
  // {path: '/hdd-ams-june', component: HDDAMSJUNE},
  // {path: '/getwallet', component: GetWallet},
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')