<template>
  <div class="container-well">

    <div
      class="backdrop"
      v-if="modalBidActive"
      @click="modalBidActive = false"
    ></div>

    <div class="mdl bid" v-if="modalBidActive">
      <h2>Create a new bid</h2>
      <div style="width: 100%; margin-top: 20px" class="clearfix">
        <h3>Premium Amount</h3>
        <input type="number" v-model="newBid" class="form-control" />
      </div>

      <div style="width: 100%; margin-top: 20px" class="clearfix">
        <h3>Collateral Requirement (Refundable)</h3>
        <input type="number" v-model="collateralRequirement" class="form-control" disabled />
      </div>

      <hr/>
    <div style="width: 100%; margin-top: 20px" class="clearfix">
        <h3>Total Payment Due</h3>
        <input type="number" :value="parseFloat(collateralRequirement) + parseFloat(newBid)" class="form-control" />
      </div>

      <div style="width: 100%; margin-top: 20px" class="clearfix">
        <a v-if="!bidLoading"
          class="btn btn-primary"
          @click="createBid()"
          style="
            float: left;
            margin-left: 5px;
            background-color: #0d6efd;
            border: none;
          "
          >Create Bid</a
        >
        <p v-if="bidLoading" style="color: black; text-align: left; margin-bottom: 0; color: #333">Creating Bid</p>
        <div v-if="bidLoading" style="margin-top: 10px; float: left" class="loader-mini"></div>


      </div>
    </div>

    <div class="col-md-3 sidebar">
      <div style="padding: 20px">
        <h3 class="title">Insurance Status</h3>
        <h2 class="subtitle">{{ request.status }}</h2>

        <h3 class="title">Collateral Requirement</h3>
        <h2 class="subtitle" v-if="request.coverageType == 'HDD'">
          ${{ (request.threshold - request.limit) * request.tickSizePayout }}
        </h2>
        <h2 class="subtitle" v-if="request.coverageType != 'HDD'">
          ${{ (request.limit - request.threshold) * request.tickSizePayout }}
        </h2>

        <h3 class="title">Coverage Type</h3>
        <h2 class="subtitle">{{ request.coverageType }}</h2>

        <h3 class="title">Threshold</h3>
        <h2 class="subtitle">{{ request.threshold }}</h2>

        <h3 class="title">Limit</h3>
        <h2 class="subtitle">{{ request.limit }}</h2>

        <h3 class="title">Payout per Unit</h3>
        <h2 class="subtitle">${{ request.tickSizePayout }}</h2>

        <div class="clearfix">
          <a
            style="
              float: left;
              margin-left: 5px;
              background-color: rgb(26, 160, 104);
              border: none;
            "
            :href="`https://goerli-optimism.etherscan.io/address/${request.coverageContract}`"
            class="btn btn-primary"
            target="_blank"
            >View Smart Contract</a
          >
        </div>

        
      </div>
    </div>

    <div class="col-md-9 maincontent">
      <h3 class="title" >Bids from underwriters</h3>
      <p 
        v-if="request.bids.length > 0 && request.status == 'open'"
        style="color: #c7ced8; padding-left: 10px; width: 60%; text-align: left"
      >
        View all existing bids below. Placing bids is free, collateral will be transfered to the smart contract when your bid is accepted. 
      </p>

      <p 
        v-if="request.bids.length == 0 && request.status == 'open'"
        style="color: #c7ced8; padding-left: 10px; width: 60%; text-align: left"
      >
        No bids have yet been placed on this coverage request. Placing bids is free, collateral will be transfered to the smart contract when your bid is accepted. 
      </p>
      

          <div class="connect-wallet" v-if="!account">
              <h3 style="color: white; font-size: 15px; margin-top: 0px; font-weight: 600; text-align: left;" v-if="!account"> To create a new bid you need to connect to your Ethereum wallet.</h3>
              <a class="nav-link" @click="connectWallet()" style="background-color: #303246; margin-top: 20px; cursor: pointer; color: white; border-radius: 14px;padding: 8px 20px; font-weight: 500;">Connect Ethereum Wallet</a>
              <a class="nav-link" href="/getwallet" style="background-color: #303246; margin-top: 15px; cursor: pointer; color: white; border-radius: 14px;padding: 8px 20px; font-weight: 500;">How do I get a wallet?</a>
          </div>

          

          <div style="width: 100%; margin-bottom: 70px; margin-top: 15px;" v-if="account && request.status == 'open'" class="clearfix;">
        <a 
            class="btn btn-primary"
            @click="modalBidActive = true"
            style="
            float: left;
            margin-left: 5px;
            background-color: #0d6efd;
            border: none;
            "
            >Create New Bid</a
        >
      </div>
      <div style="height: calc(100vh - 100px)!important; overflow: scroll">
      <div class="bid" v-for="bid in request.bids" :key="bid._id">
        <table>
           <tr v-if="bid.lowestBid == true">
            <td class="key" style="color: white">
              Lowest Bid
              <i
                class="fa fa-check-circle"
                style="color: rgb(26, 160, 104)"
              ></i>
            </td>
            <td class="value"></td>
          </tr>

          <tr v-if="request.winningBidId && bid.won == 1">
            <td class="key" style="color: white">
              This bid has been selected, coverage was activated
              <i
                class="fa fa-check-circle"
                style="color: rgb(26, 160, 104)"
              ></i>
            </td>
            <td class="value"></td>
          </tr>
          <tr>
            <td class="key">Bid Identifier</td>
            <td class="value">{{ bid.bidId }}</td>
          </tr>
          <tr>
            <td class="key">Premium</td>
            <td class="value">${{ bid.bidPremium / (1e18) }}</td>
          </tr>
          <tr>
            <td class="key">Underwriter</td>
            <td class="value">{{ bid.underwriterAddress }}</td>
          </tr>
          <tr>
            <td class="key">Timestamp</td>
            <td class="value">{{ bid.createdAt }}</td>
          </tr>
        </table>
      </div>
      </div>
      
    </div>
    
  </div>
</template>


<script>
import Vue from "vue"
import { mapGetters } from "vuex";
import axios from "axios";
import { CoverageContract } from "@/abis/CoverageContract.js"
import { ERC20Contract } from '../abis/ERC20Contract.js'
import { Line } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  PointElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
);

Vue.component("line-chart", {
  extends: Line,
});

export default {
  data() {
    return {
      request: {},
      requestInfo: "",
      modalBidActive: false,
      bidLoading: false,
      newBid: 10
    };
  },
  components: {},
  watch: {
    account() {
      if(this.request.creator) {
        if(this.request.creator.toUpperCase() === this.account.toUpperCase()) {
          window.location.replace(`/myrequest/${this.$route.params.address}`)
        }
      }
    },
    request() {
      // we only want to select the chosen bid when insurance is in progress
      if(this.request.status == "in progress") {
        this.request.bids = this.request.bids.filter(bid => bid.won == 1)
      }
    }
  },
  async mounted() {
    if (this.$route.params.address) {
      // get status
      let res = await axios.get(
        `https://whispering-reaches-79712.herokuapp.com/api/contracts/address?address=${this.$route.params.address}`
      );

      if (res.data.success) {
        if(res.data.contract.creator.toUpperCase() === this.account.toUpperCase()) {
          window.location.replace(`/myrequest/${this.$route.params.address}`)
        }
        this.request = this.cleanupContractData(res.data.contract);
      }
    } else {
      // invalid page
    }
  },
  computed: {
    ...mapGetters({
      getProviderLoggedIn: "getProviderLoggedIn",
      web3store: "getWeb3",
      account: "getAccount",
      balance: "getBalance",
      wrongNetworkMessage: "getWrongNetworkMessage",
    }),
    
    computedChartData() {

    if(this.request.threshold && this.request.limit && this.request.tickSizePayout) {
      
      let minnedPayout = (this.request.threshold-this.request.limit) * this.request.tickSizePayout
      let minPayout = (this.request.threshold-this.request.limit) * this.request.tickSizePayout
      let midPayout = 0 // break even 
      let maxPayout = (this.request.limit - this.request.threshold) * this.request.tickSizePayout 
      let maxedPayout = (this.request.limit - this.request.threshold) * this.request.tickSizePayout 

      let minnedPayoutLabel = this.request.threshold - (2 * (this.request.limit - this.request.threshold))
      let minPayoutLabel = this.request.threshold - (this.request.limit - this.request.threshold)
      let midPayoutLabel = this.request.threshold // hdd = threshold
      let maxPayoutLabel = this.request.limit 
      let maxedPayoutLabel = this.request.limit * 2


      return {
        labels: [minnedPayoutLabel, minPayoutLabel, midPayoutLabel, maxPayoutLabel, maxedPayoutLabel],
        datasets: [ { segment: {
        borderColor: (ctx) => {
        const xVal = ctx.p1.parsed.x;
        if (xVal <= 2) {
          return '#df0657';
        } else if (xVal <= 10) {
          return '#24bf73'
        } 
      }},borderColor: '#24bf73', pointRadius: 0, showLine: true,  data: [minnedPayout, minPayout, midPayout, maxPayout, maxedPayout ] } ]
    }

    } else {
      return {
        labels: [1, 2, 3],
        datasets: [ {borderColor: '#f87979', showLine: true, label: 'Payout / HDD Outcome', data: [10, 20, 30], backgroundColor: '#f87979'}],
        
      }
    }
    },
    isHomePage: function () {
      return false;
    },

    collateralRequirement: function () {
      let col = 0;
      if (this.request.coverageType == "below threshold") {
        col =
          (this.request.threshold - this.request.limit) *
          this.request.tickSizePayout;
      } else {
        col =
          (this.request.limit - this.request.threshold) *
          this.request.tickSizePayout;
      }
      return col;
    },
  },

  async created() {},
  methods: {
  connectWallet() {
      this.$store.commit("connectWallet")
  },
   async createBid() {
       try {
            this.bidLoading = true;
            let contractAddress = this.$route.params.address
            let contract = await new this.web3store.eth.Contract(CoverageContract, contractAddress);

            // test optimism token that has a faucet
            let daiContract = await new this.web3store.eth.Contract(ERC20Contract, "0x32307adffe088e383afaa721b06436adaba47dbe" );

            this.$store.commit("setLoadingMessage", "1/2 Approve Spending of Dai - Waiting Approval")
            await daiContract.methods.approve(contractAddress, this.web3store.utils.toWei((this.collateralRequirement + this.newBid).toString(),'ether')).send({from: this.account, gas: '2000000'}).on('transactionHash', (hash) => {
            this.$store.commit("setLoadingMessage", `2/2 Approving Spending of Dai... (${hash})`)
            }).on('receipt', receipt => {
              this.$store.commit("setLoadingMessage", "")
              console.log(receipt)
            }).on('error', (error, receipt) => {
              console.log(error, receipt)
              this.$store.commit("setLoadingMessage", "")
            }) 
            
            this.$store.commit("setLoadingMessage", "2/2 Create Bid - Waiting Approval")
            await contract.methods.createUnderwriteBid(this.newBid).send({from: this.account, gas: '2000000'}).on('transactionHash', (hash) => {
            this.$store.commit("setLoadingMessage", `2/2 Creating Bid... (${hash})`)
            }).on('receipt', receipt => {
              this.$store.commit("setLoadingMessage", "")
              console.log(receipt)
              this.bidLoading = false
              this.modalBidActive = false
              // reload page to get new data
              this.$router.go() 
              // reload page
            }).on('error', (error, receipt) => {
              console.log(error, receipt)
              this.$store.commit("setLoadingMessage", "")
              this.bidLoading = false
            }) 
       } catch (e) {
           console.log(e)
           this.$store.commit("setLoadingMessage", "")
           this.bidLoading = false
       }
    },

    cleanupContractData(contract) {
      console.log(contract, "contract")
      delete contract._id;
      delete contract._v;
      delete contract.updatedAt;
      delete contract.__v;
      delete contract.createdAt;

      //
      if (contract.coverageType == 1) {
        contract.coverageType = "HDD Long";
        contract.collateral =
          (contract.limit - contract.threshold) * contract.tickSizePayout;
      } else {
        contract.coverageType = "HDD Short";
        contract.collateral =
          (contract.threshold - contract.limit) * contract.tickSizePayout;
      }

      if (contract.status == 0) contract.status = "open";
      if (contract.status == 1) contract.status = "in progress";
      if (contract.status == 2) contract.status = "oracle requested";
      if (contract.status == 3) contract.status = "settled";
      if (contract.status == 5) contract.status = "cancelled";

      if (contract.status == 0) contract.action = "create offer";
      if (contract.status != 0) contract.action = "view details";
      return contract;
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  background-color: #1c1d27;
  float: left;
  min-height: calc(100vh - 80px);
  padding: 0;
}

.maincontent {
  float: left;
  padding: 20px;
}

.title {
  color: white;
  font-weight: 600;
  padding-left: 10px;
  text-align: left;
  font-size: 17px;
}
.subtitle {
  color: #c7ced8;
  font-weight: 400;
  padding-left: 10px;
  text-align: left;
  font-size: 15px;
}

.bid {
  padding: 20px;
  margin-bottom: 7px;
  width: unset;
  color: #c7ced8;
  background-color: #1c1d27;
  border-radius: 2px;
}

table {
  tr {
    td {
      text-align: left;
      padding-right: 50px;
      &.key {
        font-weight: 500;
      }
    }
  }
}

.backdrop {
  backdrop-filter: blur(8px);
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mdl {
  z-index: 4;
  position: fixed;
  width: 500px;
  top: 200px;
  left: calc(50% - 250px);
  background-color: white;
  border-radius: 5px;
  &.bid {
    h2 {
      text-align: left;
      font-size: 16px;
      color: black;
    }

    h3 {
      width: 100%;
      text-align: left;
      font-size: 15px;
      color: black;
    }

    input {
      float: left;
      width: 70%;
    }
  }
}

// spinner 
.loader-mini,
.loader-mini:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}
.loader-mini {
  margin: 0px auto;
  margin-top: 1px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid #171721;
  border-right: 0.5em solid #171721;
  border-bottom: 0.5em solid #171721;
  border-left: 0.5em solid white;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>