<template>
<div class="navigation-bar">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <router-link class="navbar-brand" to="/"><strong>Vulcan</strong></router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarCollapse">
      <ul class="navbar-nav" style="padding-right: 0px">
        <li class="nav-item" style="padding-top: 10px; padding-bottom: 5px;">
          <a v-if="account" class="nav-link" style=" border-radius: 14px;padding: 8px 20px; font-weight: 500;">{{account.substring(0,13) + ".."}}</a>
          <a v-if="!account" id="connect-w" class="nav-link" @click="connectWallet()" style="cursor: pointer; background-color: transparent; border-radius: 14px; color: white; padding: 8px 20px; font-weight: 500;">Connect Wallet</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
</div>
</template>

<script>
import Web3 from "web3";
import { mapGetters} from "vuex"
export default {
  name: 'header',
   data() {
    return {
      
    }
  },
  watch: {
    getConnectWallet() {
      this.connectWallet()
    }
  },
  props: {
    msg: String
  },
  methods: {
    async connectWallet() {
     if (!window.web3 || !window.ethereum) {
      // redirect when no provider can be loaded
      if (window.location.pathname != "/getwallet")
        window.location.replace("/getwallet");
    } else if (window.ethereum) {
        // new provider system
        let ethereum = window.ethereum;
        let web3 = new Web3(ethereum);
        await ethereum.enable();
        this.$store.commit("setWeb3", web3);
        this.getAccounts();
    } else if (window.web3) {
        // legacy provider system
        let web3 = new Web3(window.web3.currentProvider);
        this.$store.commit("setWeb3", web3);
        this.getAccounts();
    }
    },
    async getAccounts() {
      let accounts = await this.web3store.eth.getAccounts();
      this.$store.commit("setAccount", accounts[0]);
      this.$store.dispatch("getBalance", this.account);
      this.getBalance = () => {
        this.$store.dispatch("getBalance", this.account);
        setTimeout(() => {
          this.getBalance();
        }, 10000);
      };
    }
  },
  mounted() {
   this.connectWallet()
  },
    computed: {
     ...mapGetters({
      getProviderLoggedIn: "getProviderLoggedIn",
      web3store: "getWeb3",
      account: "getAccount",
      getConnectWallet: "getConnectWallet",
      balance: "getBalance",
      wrongNetworkMessage: "getWrongNetworkMessage"
    }),
    isHomePage: function()  {
      return true
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.navigation-bar {

    .navbar-light {
      padding-left: 1%;
      background: #8A2387;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #F27121, #E94057, #8A2387);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #F27121, #E94057, #8A2387); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


    }
    .container-fluid {
        .navbar-brand {
          margin-top: 6px;
          margin-left: 10px;
            color: white;
            font-weight: 200;
        }
    }
    .navbar-collapse {
        ul.navbar-nav {
            li.nav-item {
                a.nav-link {
                    color: #c7ced8;
                } 
            }
        }
    }
}

.nav-item {
     a.nav-link {
         color: white;
     }
}
</style>
