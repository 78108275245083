export default [
    {
        label: "Contract",
        field: "contractAddress",
        type: "text",
      },
    {
        label: "Premium Offered",
        field: "bidPremium",
        type: "text",
    },
      {
        label: "created",
        field: "createdAt",
        type: "text",
      },
      {
        label: "status",
        field: "won",
        type: "text",
      }
  ]