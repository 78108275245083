export default [
    {
        label: "Product ID",
        field: "contractObject.productId",
        type: "text",
    },
    {
        label: "Status",
        field: "contractObject.status",
        type: "text",
    },
    {
        label: "Direction",
        field: "contractObject.coverageType",
        type: "number",
      },
      {
        label: "Threshold",
        field: "contractObject.threshold",
        type: "number",
      },
    {
        label: "NFT Id",
        field: "tokenId",
        type: "text",
    },
    {
        label: "contract",
        field: "contract",
        type: "text",
    }

  ]