export default [
    {
        label: "Product Id",
        field: "id",
        type: "text",
    },
    {
        label: "Product Name",
        field: "productName",
        type: "text",
    },
    {
        label: "Oracle Address",
        field: "oracleAddress",
        type: "text",
    },
    {
        label: "Product Settlement Strategy",
        field: "productSettlementAddress",
        type: "text",
    },
    {
      label: "chainlink job id",
      field: "jobId",
      type: "text",
    },
    {
      label: "Product Fee",
      field: "productFee",
      type: "number",
    },
    {
      label: "NFT address",
      field: "productOwnershipToken",
      type: "text",
    }
  ]