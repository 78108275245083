<template>
  <div class="row">
    <div class="col-md-2 sidebar">
      <h2 style="color: #e0e0e0; font-size: 15px; text-align: left; margin-bottom: 15px; ">Exchange</h2>
      <h3 class="menu-item" @click="setActiveTab(0)" :class="{'active': activeTab == 0}">Policies</h3>
      <h3 class="menu-item" @click="setActiveTab(3)" :class="{'active': activeTab == 3}">Reinsurance Market</h3>
      <h3 class="menu-item" @click="setActiveTab(2)" :class="{'active': activeTab == 2}">Product Specs</h3>
      <hr>
      <h2 style="color: #e0e0e0; font-size: 15px; text-align: left;  margin-bottom: 15px;">My Account</h2>
      <h3 class="menu-item" @click="setActiveTab(6)" :class="{'active': activeTab == 6}">My Requests</h3>
      <h3 class="menu-item" @click="setActiveTab(5)" :class="{'active': activeTab == 5}">Bids</h3>
      <h3 class="menu-item" @click="setActiveTab(1)" :class="{'active': activeTab == 1}">Portfolio</h3>
      <h3 class="menu-item" @click="setActiveTab(4)" :class="{'active': activeTab == 4}">Settings</h3>
      <hr>
    </div>
    <div class="col-md-10 dashboard">
      <div class="dashboard-inner">
        <!-- my requests -->
        <div class="contract-table" style="margin-top: 0px" v-if="activeTab == 6">
          <div class="blockdata" style="padding-right: 10px;">
            Last Block: <span class="blockset">#{{ lastBlock }} </span>
            <i class="fas fa-wifi" style="color: #19bb24; margin-left: 5px;"></i>
          </div>
           <h2 class="title">My Requests</h2>
          <p class="subtitle">
            Overview of your insurance requests
          </p>

          <div class="connect-wallet" v-if="!account">
              <h3 style="color: white; font-size: 15px; margin-top: 0px; font-weight: 600; text-align: left;" v-if="!account"> To see your bids you need to connect to your Ethereum wallet.</h3>
              <a class="nav-link" @click="connectWallet()" style="background-color: #303246; margin-top: 20px; cursor: pointer; color: white; border-radius: 14px;padding: 8px 20px; font-weight: 500;">Connect Ethereum Wallet</a>
              <a class="nav-link" href="/getwallet" style="background-color: #303246; margin-top: 15px; cursor: pointer; color: white; border-radius: 14px;padding: 8px 20px; font-weight: 500;">How do I get a wallet?</a>
          </div>

          <vue-good-table v-if="!loading.mycontracts && account"
            :columns="myRequestTable.columns"
            :rows="myRequestTable.rows"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
            }"
            :search-options="{
              enabled: true,
              placeholder: 'Search Requests',
            }"
            id="contracts-all"
            theme="nocturnal"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <div
                  @click="openDetailRequest(props.row.coverageContract)" style="
                    background-color: #1aa068;
                    border-radius: 5px;
                    margin-bottom: 0;
                    margin-top: -4px;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 400;
                    color: white;
                    width: 100%;
                    padding: 5px 7px;
                    cursor: pointer
                  "
                >
                  {{ props.row.action }}
                </div>
              </span>
              <span v-else-if="props.column.field == 'productId'">
                <div
                  @click="setActiveTab(2);"
                  style="
                    cursor: pointer;
                    background-color: #1aa09a;
                    border-radius: 5px;
                    margin-bottom: 0;
                    margin-top: -4px;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 400;
                    color: white;
                    width: 100%;
                    padding: 3px 4px;
                  "
                >
                  <span v-if="loading.products">{{ props.row.productId }} <div style="width: 20px; height: 20px; float: right"><div class="loader-mini"></div></div></span>
                  <span v-if="!loading.products">{{ props.row.productId }} </span>
 
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>

            </template>
          </vue-good-table>
          <div class="lds-ellipsis" v-if="loading.mycontracts && account.length > 0"><div></div><div></div><div></div><div></div></div>

        </div>

        <!-- bids -->
        <div class="contract-table" style="margin-top: 0px" v-if="activeTab == 5">
          <div class="blockdata" style="padding-right: 10px;">
            Last Block: <span class="blockset">#{{ lastBlock }} </span>
            <i class="fas fa-wifi" style="color: #1aa068; margin-left: 5px;"></i>
          </div>
          <h2 class="title">All Bids</h2>
          <p class="subtitle">
            All bids that you have placed on a contract. When your bid is chosen you will be able to track the contract in the portfolio section
          </p>
          <div class="connect-wallet" v-if="!account">
              <h3 style="color: white; font-size: 15px; margin-top: 0px; font-weight: 600; text-align: left;" v-if="!account"> To see your bids you need to connect to your Ethereum wallet.</h3>
              <a class="nav-link" @click="connectWallet()" style="background-color: #303246; margin-top: 20px; cursor: pointer; color: white; border-radius: 14px;padding: 8px 20px; font-weight: 500;">Connect Ethereum Wallet</a>
              <a class="nav-link" href="/getwallet" style="background-color: #303246; margin-top: 15px; cursor: pointer; color: white; border-radius: 14px;padding: 8px 20px; font-weight: 500;">How do I get a wallet?</a>
          </div>
          <vue-good-table v-if="account"
            :columns="activeBids.columns"
            :rows="activeBids.rows"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
            }"
            :search-options="{
              enabled: true,
              placeholder: 'Search Bids',
            }"
            id="contracts-all"
            theme="nocturnal"
          >
          </vue-good-table>
          <div class="lds-ellipsis" v-if="loading.bids && account.length > 0"><div></div><div></div><div></div><div></div></div>

        </div>

        <!-- all product specs -->
        <div class="contract-table" style="margin-top: 0px" v-if="activeTab == 2">
          <div class="blockdata" style="padding-right: 10px;">
            Last Block: <span class="blockset">#{{ lastBlock }} </span>
            <i class="fas fa-wifi" style="color: #1aa068; margin-left: 5px;"></i>
          </div>
          <h2 class="title">All Product Specifications</h2>
          <p class="subtitle">
            Overview of all products that are registered on the blockchain. 
          </p>
          <vue-good-table v-if="!loading.products"
            :columns="productsTable.columns"
            :rows="productsTable.rows"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
            }"
            :search-options="{
              enabled: true,
              placeholder: 'Search Requests',
            }"
            id="contracts-all"
            theme="nocturnal"
          >
          </vue-good-table>
          <div class="lds-ellipsis" v-if="loading.products"><div></div><div></div><div></div><div></div></div>

        </div>

         <div class="contract-table" style="margin-top: 0px" v-if="activeTab == 4">
          <h2 class="title">Settings</h2>
          <p class="subtitle" style="width: 60%">For this version (Optimism Goerli) settings are disabled.</p>
        </div>

        <div class="contract-table" style="margin-top: 0px" v-if="activeTab == 3">
          <h2 class="title">Reinsurance Market</h2>
          <p class="subtitle" style="width: 60%">The underwriter as well as the policy holder already receive a unique token for each contract. These tokens can be transferred to other wallets using wallets such as Metamask. We will be building our own interface for this soon to improve the user experience.</p>
        </div>
        <!-- my requests -->
        <div class="contract-table" style="margin-top: 0px" v-if="activeTab ==1">
          <div class="blockdata" style="padding-right: 10px;">
            Last Block: <span class="blockset">#{{ lastBlock }} </span>
            <i class="fas fa-wifi" style="color: #19bb24; margin-left: 5px;"></i>
          </div>
          <h2 class="title">Portfolio</h2>
           <p class="subtitle" style="width: 60%;">
            Overview of coverage contracts that are filled and tradeable on secondary markets. The contracts are tokenized with an ERC721 token (NFT) and can be traded by any exchange that supports it.
          </p>

            <div class="connect-wallet" v-if="!account">
              <h3 style="color: white; font-size: 15px; margin-top: 0px; font-weight: 600; text-align: left;" v-if="!account"> To see your products you need to connect to your Ethereum wallet.</h3>
              <a class="nav-link" @click="connectWallet()" style="background-color: #303246; margin-top: 20px; cursor: pointer; color: white; border-radius: 14px;padding: 8px 20px; font-weight: 500;">Connect Ethereum Wallet</a>
              <a class="nav-link" href="/getwallet" style="background-color: #303246; margin-top: 15px; cursor: pointer; color: white; border-radius: 14px;padding: 8px 20px; font-weight: 500;">How do I get a wallet?</a>
          </div>

          <vue-good-table v-if="account"
            :columns="nftOverviewTable.columns"
            :rows="nftOverviewTable.rows"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
            }"
            :search-options="{
              enabled: true,
              placeholder: 'Search Requests',
            }"
            id="contracts-all"
            theme="nocturnal"
          >
           <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <div
                  style="
                    background-color: #1aa068;
                    border-radius: 5px;
                    margin-bottom: 0;
                    margin-top: -4px;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 400;
                    color: white;
                    width: 100%;
                    padding: 5px 10px;
                  "
                >
                  {{ "Transfer"}}
                </div>
              </span>
            <span v-else-if="props.column.field == 'contractObject.productId'">
              <div
                @click="setActiveTab(2);"
                style="
                  cursor: pointer;
                  background-color: #1aa09a;
                  border-radius: 5px;
                  margin-bottom: 0;
                  margin-top: -4px;
                  text-align: center;
                  font-size: 15px;
                  font-weight: 400;
                  color: white;
                  width: 100%;
                  padding: 3px 4px;
                "
              >
                <span v-if="loading.products">{{ props.row.contractObject.productId }} <div style="width: 20px; height: 20px; float: right"><div class="loader-mini"></div></div></span>
                <span v-if="!loading.products">{{ props.row.contractObject.productId }} </span>

              </div>
            </span>
            </template>
          </vue-good-table>
          <div class="lds-ellipsis" v-if="loading.nfts && account.length > 0"><div></div><div></div><div></div><div></div></div>


        </div>

        <!-- open requests -->
        <div class="contract-table" style="margin-top: 0px" v-if="activeTab == 0">
          <div class="blockdata" style="padding-right: 10px;">
            Last Block: <span class="blockset">#{{ lastBlock }} </span>
            <i class="fas fa-wifi" style="color: #19bb24; margin-left: 5px;"></i>
          </div>
          <h2 class="title">Requests</h2>
          <p class="subtitle">
            Overview of insurance requests. If the request is not filled you are able to submit an
            offer through the dashboard or directly on the Ethereum network.
          </p>
          <vue-good-table v-if="!loading.contracts"
            :columns="openRequestTable.columns"
            :rows="openRequestTable.rows"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
            }"
            :search-options="{
              enabled: true,
              placeholder: 'Search Requests',
            }"
            id="contracts-all"
            theme="nocturnal"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <div
                  @click="openDetailRequest(props.row.coverageContract)" style="
                    background-color: #1aa068;
                    border-radius: 5px;
                    margin-bottom: 0;
                    margin-top: -4px;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 400;
                    color: white;
                    width: 100%;
                    padding: 5px 7px;
                    cursor: pointer;
                  "
                >
                  {{ props.row.action }}
                </div>
              </span>
              <span v-else-if="props.column.field == 'productId'">
                <div
                  @click="setActiveTab(2);"
                  style="
                    cursor: pointer;
                    background-color: #1aa09a;
                    border-radius: 5px;
                    margin-bottom: 0;
                    margin-top: -4px;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 400;
                    color: white;
                    width: 100%;
                    padding: 3px 4px;
                  "
                >
                  <span v-if="loading.products">{{ props.row.productId }} <div style="width: 20px; height: 20px; float: right"><div class="loader-mini"></div></div></span>
                  <span v-if="!loading.products">{{ props.row.productId }} </span>
 
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>

            </template>
          </vue-good-table>
          <div class="lds-ellipsis" v-if="loading.contracts"><div></div><div></div><div></div><div></div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from "jquery";
import { io } from "socket.io-client";
import axios from "axios";
import openRequestStructure from "../tableColumns/openRequestTable"
import NFTOverviewTableStructure from "../tableColumns/nftOverviewTable"
import bidOverviewTable from "../tableColumns/bidOverviewTable"
import productsTable from "../tableColumns/productsTable"
import { mapGetters } from 'vuex' 
import moment from 'moment'

export default {
  name: "home",
  async mounted() {
    if(this.$route.query.activeTab) {
      this.activeTab = this.$route.query.activeTab
    }
    this.setupSockets();

    // get open contracts
    let data = await this.getContracts();
    let formattedData = this.cleanupContractData(data.contracts);
    this.openRequestTable.columns = openRequestStructure;
    this.openRequestTable.data = formattedData;
    this.openRequestTable.rows = this.openRequestTable.data
    this.loading.contracts = false
    this.getProductData(data.lastProductId);

  },
  watch: {
    account(newAccount, oldAccount) {
      if(oldAccount.length > 0) {
        window.location.replace("/dashboard?activeTab=" + this.activeTab)
      }
      this.getOwnershipTokens()
      this.getBids()
      this.getAccountData()
    },
  },
  computed: {
     ...mapGetters({
      getProviderLoggedIn: "getProviderLoggedIn",
      web3store: "getWeb3",
      account: "getAccount",
    }),
  },
  data() {
    return {
      loading: {
        contracts: true, 
        products: true,
        bids: true,
        nfts: true,
        mycontracts: true
      },
      lastBlock: 0,
      activeTab: 0,
      allProducts: [],
      nftOverviewTable: {
        columns: [],
        rows: [],
        data: []
      },
      productsTable: {
        columns: [],
        rows: [],
        data: []
      },
      myRequestTable: {
        columns: [],
        rows: [],
        data: []
      },
      openRequestTable: {
        columns: [],
        rows: [],
        data: [],
      },
      activeBids: {
        columns: [],
        rows: [],
        data: []
      }
    };
  },
  components: {},
  methods: {
    async getAccountData() {
        let data = await this.getMyContracts(this.account)
        let formattedData = this.cleanupContractData(data.contracts);
        this.myRequestTable.columns = openRequestStructure;
        this.myRequestTable.data = formattedData;
        this.myRequestTable.rows = this.myRequestTable.data
        this.loading.mycontracts = false
        this.getProductData(data.lastProductId);
    },
    async getMyContracts(address) {
      console.log(address)
      try {
        let res = await axios.get("https://whispering-reaches-79712.herokuapp.com/api/contracts?address=" + address);
        if (res.data.lastBlockProcessed)
          this.lastBlock = res.data.lastBlockProcessed;
        return res.data;
      } catch (e) {
        console.log(e);
      }
    },
    async getBids() {
      try {
        let req = await axios.get("https://whispering-reaches-79712.herokuapp.com/api/contracts/bids/address?address=" + this.account)
        let bids = req.data.bids
        bids = bids.map(bid => {
          if(bid.won == 0) bid.won = "lost"
          else if(bid.won == 1) bid.won = "won"
          else if(bid.won == 2) bid.won = "in progress"
          return bid
        })
        this.activeBids.rows = bids
        this.activeBids.columns = bidOverviewTable
        this.loading.bids = false
      } catch (e) {
        console.log(e)
      }
    },
    async getOwnershipTokens() {
      try {
        let req = await axios.get("https://whispering-reaches-79712.herokuapp.com/api/contracts/tokens/address?address=" + this.account)
        console.log(req)
        let nfts = req.data.nfts.map(nft => {
          nft.ownershipType == 0 ? nft.ownershipType = 'requestor' : nft.ownershipType = 'underwriter'
          nft.contractObject = this.cleanupContractData([nft.contractObject])[0]
          return nft
        })
        this.nftOverviewTable.columns = NFTOverviewTableStructure
        this.nftOverviewTable.rows = nfts

        this.loading.nfts = false

        if(this.allProducts.length > 0) {
          this.nftOverviewTable.rows.forEach(row => {
            row.productRawId = row.contractObject.productId
            row.contractObject.productId = this.allProducts.find(x => x.id === row.contractObject.productId.toString()).productName;
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    connectWallet() {
      this.$store.commit("connectWallet")
    },
    async getProductData(count) {
      try {
        this.productsTable.rows = []
        let promiseArray = []
        for(let i =1; i < count + 1; i++) {
          let req = await axios.get("https://whispering-reaches-79712.herokuapp.com/api/contracts/products?id=" + i);
          promiseArray.push(req)
        }
        Promise.all(promiseArray).then(res => {
          this.productsTable.columns = productsTable
          this.allProducts = []
          res.forEach((response) => {
            this.allProducts.push(response.data.product)
             this.productsTable.rows.push(response.data.product)
          })

          // update products in contracts
          this.openRequestTable.rows.forEach(row => {
            row.productRawId = row.productId
            row.productId = this.allProducts.find(x => x.id === row.productId.toString()).productName;
          })


          this.nftOverviewTable.rows.forEach(row => {
            row.productRawId = row.contractObject.productId
            row.contractObject.productId = this.allProducts.find(x => x.id === row.contractObject.productId.toString()).productName;
          })
          this.loading.products = false
        })
      } catch (e) {
        console.log(e)
      }
    },
    setActiveTab(id) {
      this.activeTab = id
    },
    updateTable(tableName, data) {
      this[tableName].data.push(data);
      this[tableName].rows = this[tableName].data;
    },
    cleanupContractData(contractData) {
      return contractData.map((contract) => {
        delete contract._id;
        delete contract._v;
        delete contract.updatedAt;
        delete contract.__v;
        delete contract.createdAt;

        contract.dateStart = moment.unix(contract.metadata.split('|')[3]).subtract('days', contract.metadata.split('|')[2]).format("MM/DD/YYYY");
        contract.dateEnd = moment.unix(contract.metadata.split('|')[3]).format("MM/DD/YYYY");

        if (contract.coverageType == 1) {
          contract.coverageType = "HDD Call";
          contract.collateral =
            (contract.limit - contract.threshold) * contract.tickSizePayout;
        } else {
          contract.coverageType = "HDD Put";
          contract.collateral =
            (contract.threshold - contract.limit) * contract.tickSizePayout;
        }

        if (contract.status == 0) contract.status = "open";
        if (contract.status == 1) contract.status = "in progress";
        if (contract.status == 2) contract.status = "oracle requested";
        if (contract.status == 3) contract.status = "settled";
        if (contract.status == 5) contract.status = "cancelled";
        
        if(contract.status == 0) contract.action = "create offer";
        if(contract.status != 0) contract.action = "view details"
        return contract;
      });
    },
    openDetailRequest(requestId) {
      if(this.activeTab == 6) {
        window.location = `/myrequest/${requestId}`
      } else {
        window.location = `/request/${requestId}`
      }
    },
    setupSockets() {
      const socket = io("https://whispering-reaches-79712.herokuapp.com");

      // socket.on("close", tryReconnect);

      socket.on("connect", () => {
        console.log("connected");
      });

      socket.on("update-block", (block) => {
        if (block.number) {
          this.lastBlock = block.number;
        }
      });

      socket.on("new-nft", () => {
        this.getProductData()
      })

      socket.on("new-contract", (contract) => {
        let nc = this.cleanupContractData([contract]);
        this.updateTable("openRequestTable", nc[0]);
      });

      const tryReconnect = () => {
        console.log("attempting reconnection");
        setTimeout(() => {
          socket.open((err) => {
            if (err) {
              tryReconnect();
            }
          });
        }, 2000);
      };
    },
    async getContracts() {
      try {
        let res = await axios.get("https://whispering-reaches-79712.herokuapp.com/api/contracts");
        if (res.data.lastBlockProcessed)
          this.lastBlock = res.data.lastBlockProcessed;
        return res.data;
      } catch (e) {
        console.log(e);
      }
    },
  },
  props: {
    msg: String,
  },
};
</script>


<style lang="scss">

.connect-wallet {
  width: 400px;
  padding: 20px 20px;
  padding-bottom: 30px;
}
.vgt-wrap.nocturnal .vgt-global-search__input .vgt-input,
.vgt-wrap.nocturnal .vgt-global-search__input .vgt-select {
  color: #f3f3f3;
  margin-bottom: 10px;
  background-color: #171721 !important;
  border: 1px solid #474747;
}

.vgt-wrap__footer .footer__row-count__label {
  font-size: 14px !important;
}

.vgt-wrap__footer .footer__row-count__select {
  font-size: 16px !important;
  margin-right: -5px !important;
  width: 60px !important;
}

.vgt-wrap.nocturnal {
  .vgt-global-search {
    border: none !important;
    background: #171721 !important;
  }

  .vgt-wrap__footer {
    background: none !important;
    background-color: #171721 !important;
    border: none !important;
    font-size: 14px;
  }
}

.blockdata {
  color: #e7e7e7;
  float: right;
  font-size: 14px;
}

#contracts-all {
  .vgt-inner-wrap {
    box-shadow: none;
  }
  .vgt-table.nocturnal {
    border: none !important;
    box-shadow: none;
  }
  .vgt-table.nocturnal thead th {
    border-radius: 0px !important;
    color: white;
    font-size: 15px;
    background: #171721 !important;
    border: none;
    border-bottom: 1px solid #474747;
  }

  td {
    background-color: #171721 !important;
    border: none;
    color: #c7ced8;
    font-size: 15px;
  }
}

.sidebar {
    .subtitle {
        color: white;
        font-size: 20px; 
        text-align: left;
        padding-left: 10px;
        margin-bottom: 20px;
        font-weight: 400;
    }
  background-color: #14141c;
  padding: 30px;
  padding-left: 40px;
  padding-top: 20px;
  h3.menu-item {
    cursor: pointer;
    color: white;
    text-align: left;
    padding-left: 20px;
    font-size: 16px;
    padding: 10px 20px;
    &.active {
      background-color: #171721;
      color: white;
      border-radius: 5px;
      border-left: 1px solid white;
       border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
.dashboard {
  background-color: #14141c;
  padding: 10px;
  padding-right: 0px;
  min-height: 100vh;
  .title {
    color: white;
    font-weight: 600;
    padding-left: 10px;
    text-align: left;
    font-size: 17px;
  }
  .subtitle {
    color: #c7ced8;
    font-weight: 400;
    padding-left: 10px;
    text-align: left;
    font-size: 15px;
  }
  .dashboard-inner {
    border-radius: 10px;
    background-color: #171721;
    min-height: calc(100vh - 20px);
  }
}
.contract-table {
  padding: 30px 40px;
}

.form {
  background-color: white;
  position: absolute;
  left: 20px;
  border-radius: 10px;
  top: 100px;
  width: 500px;
  height: 80%;
  z-index: 20;
  box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 10px 10px 61px 0px rgba(0, 0, 0, 0.55);
}

// spinner 
.lds-ellipsis {
  margin-top: 50px;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}



// 2
.loader-mini,
.loader-mini:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}
.loader-mini {
  margin: 0px auto;
  margin-top: 1px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid rgba(255, 255, 255, 0.2);
  border-right: 0.5em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.5em solid rgba(255, 255, 255, 0.2);
  border-left: 0.5em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>