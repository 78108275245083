<template>
  <div class="home">
    <div class="container-well">
        <div class="cover">
            <div class="container clearfix" style="margin-top: 70px;">
                <h1>Vulcan<strong></strong></h1>
                <h2>Commision-free Derivatives Marketplace.<br>powered by Blockchain Technology</h2>
                <a href="/products" class="btn-primary main alt remove-mobile">View all Products</a>
                <a  href="/dashboard" class="btn-primary main remove-mobile">Underwrite Products</a>
                <p style="margin-bottom: 30px;" class="remove-desktop">Creating new coverage requests only available on desktop devices</p>
                <a  href="https://j4juttvagsi.typeform.com/to/mjlSMozv" class="btn-primary main">Early Access Program</a>
            </div>
        </div>
        <!-- <div class="announcement">
            <div class="container">
            New Product : HDD Swaps New York
            </div>
        </div> -->
         <div class="about clearfix">
            <div class="container clearfix">
                <h2 style="font-size: 22px; padding: 20px; padding-bottom: 30px; font-weight: 400;">Traditionally derivatives are expensive, obscure and hard to access. <br>We make derivatives <strong>accesible</strong> and <strong>free</strong> for <strong>everyone</strong>.</h2>
                <div class="col-md-3 item">
                    <h2 class="title">Easy to understand products. No hidden fees</h2>
                    <p>We try to make our products easy to understand and transparent for everyone. No hidden quotes or middleman fees. What you see is what you get.</p>
                </div>
                <div class="col-md-3 item">
                    <h2 class="title">24/7 accessible to anyone. No Commission</h2>
                    <p>Vulcan does not rely on the opening hours of trading desks, brokerages or exchanges. We connect buyers and sellers of risk products directly without compromises. </p>
                </div>
                <div class="col-md-3 item">
                    <h2 class="title">Fully Decentralized, No custody of funds</h2>
                    <p>All our code is open-sourced, allowing anyone to review our code. Our entire platform is decentralized and hosted on the Ethereum blockchain, we never take custody of your funds. </p>
                </div>
                <div class="col-md-3 item">
                    <h2 class="title">Your blockchain wallet, your privacy</h2>
                    <p>No signup forms or invasive advertisement trackers. The only requirement is an Ethereum compatible wallet (such as Metamask or WalletConnect). </p>
                </div>
            </div>
            <div class="powered">
                <div class="container">
                    <h3 style="color: white">Powered By</h3>
                    <img class="partner" src="@/assets/openweather.png" alt="">
                    <img class="partner" src="@/assets/chainlink.png" alt="">
                    <img class="partner" src="@/assets/aave.png" alt="">
                    <a target="_blank" href="https://j4juttvagsi.typeform.com/to/mjlSMozv" class="btn-primary main alt mob-wide" style="float: right; margin-top: -5px; font-size: 14px;" >Request Early Access</a>
                </div>
            </div>
            <!-- <div class="new clearfix">
                <div class="container">
                    <div class="col-md-6">
                        <h1>Custom Products</h1>
                        <h2>Looking for a specific weather risk product that we are not yet offering? Contact us and we will make it happen.</h2>
                        <a href="" class="btn-primary main">Request Product</a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
     
    }
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.mob-wide {
    @media(max-width: 880px) {
        width: 90%!important;
        margin-left: 5%!important;
        margin-top: 30px!important;
        margin-bottom: 80px!important;
        padding: 20px;
        text-align: center;
    }
}
.home {
    background-color: white;
    color: white;
    .cover {
        padding-top: 8vh;
        padding-bottom: 8vh;
        h1 {
            font-weight: 500;
            font-size: 70px;
            @media(max-width: 880px) {
                font-size: 40px;
            }
        }
        h2 {
            margin-bottom: 30px;
            font-weight: 400;
        }
        width: 100%;
        background: #8A2387;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #F27121, #E94057, #8A2387);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #F27121, #E94057, #8A2387); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

        text-align: center;
    }
    .announcement {
        background-color: #292E44;
        color: white;
        text-align: left;
        font-weight: 600;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .remove-mobile {
        @media(max-width: 880px) {
            display: none!important;
            margin-right: 0!important;
        }
    }
    .remove-desktop {
        @media(max-width: 880px) {
            display: block!important;
        }
        display: none!important;
    }
    .new {
        background-color: #111927;
        padding-top: 40px;
        padding-bottom: 40px;
        text-align: left;
        h1 {
            font-size: 15px;
            font-weight: 600;
        }
        h2 {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 35px;
        }
    }
    .about {
        img.partner {
            margin-top: 20px;
            width: 80px;
            margin-right: 30px;
        }
        .powered {
            padding-top: 40px;
            padding-bottom: 40px;
            color: #212020;
            text-align: left; 
            @media(max-width: 880px) {
                width: 100%;
                padding: 20px;
                text-align: center;
                
            }

            h3 {
                font-size: 13px;
                font-weight: 400;
            }
        }
        background-color:#0e1123;
        .item {
            width: 22%;
            margin-right: 3%;
            float: left;
            padding-top: 40px;
            @media(max-width: 880px) {
                width: 100%;
                padding: 20px;
                text-align: center;
            }
            h2 {
                font-size: 16px;
                font-weight: 600;
            }   
            p {
                font-size: 15px;
            }
            text-align: left;
            font-size: 14px;
            color: white;
        }
    }
}


.btn-primary {
  font-weight: 600;
  background-color: #3e3f7c;
  border-radius: 12px;
  padding: 15px 20px;
  font-size: 16px;
  text-decoration: none;
  border: none;
  opacity: none;
  &.alt {
      background-color: white;
      color: #0f1123;
      @media(max-width: 880px) {
          margin-right: 0!important;
      }
  }
  &.main {
    margin-top: 20px;
    margin-right: 20px;
  }
  &.small {
    font-size: 13px;
    border-radius: 10px;
    height: 30px;
    margin-right: 5px;
    padding: 5px 12px;
    margin-top: -4px;
  }
}

</style>
