export default [
    {
      label: "Product ID",
      field: "productId",
      type: "text",
    },
    {
      label: "Direction",
      field: "coverageType",
      type: "number",
    },
    {
      label: "Threshold",
      field: "threshold",
      type: "number",
    },
    {
      label: "Limit",
      field: "limit",
      type: "number",
    },
    {
      label: "Ticksize $",
      field: "tickSizePayout",
      type: "number",
    },

    {
      label: "Status",
      field: "status",
      type: "number",
    },
    {
      label: "Start",
      field: "dateStart",
      type: "text",
    },
    {
      label: "End",
      field: "dateEnd",
      type: "text",
    },
    {
      label: "Collateral $",
      field: "collateral",
      type: "number",
    },
    {
      label: "",
      field: "action",
    },
  ]