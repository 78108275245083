<template>
  <div class="container-well">
    <div class="backdrop" v-if="acceptBidModalActive" @click="acceptBidModalActive = false"></div>
    <div class="mdl bid" v-if="acceptBidModalActive">
      <h2>Confirm Bid</h2>

      <div style="width: 100%; margin-top: 20px" class="clearfix">
        <h3>Premium Amount</h3>
        <input type="number" :value="acceptBidObject.bidPremium / 1e18" disabled class="form-control" />
      </div>

      <hr />
      <div style="width: 100%; margin-top: 20px" class="clearfix">
        <h3>Underwriter</h3>
        <input type="text" :value="acceptBidObject.underwriterAddress" disabled class="form-control" />
      </div>

      <hr/>
      <div style="width: 100%; margin-top: 20px" class="clearfix">
        <h3 style="margin-top: 20px;">Payout Overview HDD/USD</h3>
        <line-chart style="width: 200px; margin-bottom: 30px; margin-top: 20px;"
            :chart-options="{
              plugins: {  
              legend: {
                display: false,
              labels: {
              color: 'white',
              }
            }
            }}" :chart-data="computedChartData"
          />

          </div>
      <div style="width: 100%; margin-top: 10px" class="clearfix">
        <p style="text-align: left; color: black"><small>By accepting the bid you will pay the premium directly to the underwriter. The underwriter has locked up collateral to support any claims.  Payouts can be claimed immediately after the expiry of the coverage. </small></p>
        <a v-if="!bidLoading" class="btn btn-primary" @click="takeBid(acceptBidObject)" style="
            float: left;
            margin-left: 0px;
            background-color: #0d6efd;
            border: none;
          ">Accept Bid</a>
        <p v-if="bidLoading" style="color: black; text-align: left; margin-bottom: 0; color: #333">Accepting Bid..</p>
        <div v-if="bidLoading" style="margin-top: 10px; float: left" class="loader-mini"></div>
          
      </div>
    </div>

    <div class="col-md-3 sidebar">
      <!-- <div style="padding: 10px; background-color: #197579;">
              <p style="color: white; margin-bottom: 0px;">Note that coverage is not active until a bid has been accepted and the status is "in progress"</p>
      </div>  -->



      <div style="padding: 20px; position: relative" v-if="request">
        <h3 class="title">Coverage Status</h3>
        <h2 class="subtitle">{{request.status}} </h2>

        <h3 class="title">Coverage Start Date</h3>
        <h2 class="subtitle">{{getFormattedRequestDate}} </h2>

        <h3 class="title">Coverage Type</h3>
        <h2 class="subtitle">{{request.coverageType}} </h2>

        <h3 class="title">Threshold</h3>
        <h2 class="subtitle">{{request.threshold}} </h2>

        <h3 class="title">Limit</h3>
        <h2 class="subtitle">{{request.limit}} </h2>

        <h3 class="title">Payout per Unit</h3>
        <h2 class="subtitle">{{request.tickSizePayout}} </h2>


        <h3 class="title">Summary</h3>
        <h2 class="subtitle">
          You will get paid <strong>{{request.tickSizePayout}}</strong> Dai per HDD when there
          are <span v-if="coverageType != 'below threshold'">less</span><span
            v-if="coverageType == 'below threshold'">more</span> than <strong>{{request.threshold}}</strong> cumulative
          HDD’s in June.
          <br><br>
          <strong>Note that coverage is not active until a bid is taken</strong>
        </h2>

        <div class="clearfix">
          <a style="float: left; margin-left: 5px; background-color: rgb(26, 160, 104); border: none;"
            :href="`https://goerli-optimism.etherscan.io/address/${request.coverageContract}`" class="btn btn-primary"
            target="_blank">View Smart Contract</a>
        </div>



      </div>

    </div>

    <div class="col-md-9 maincontent">
      <h3 class="title">Bids from underwriters</h3>
      <p v-if="request.bids.length > 0" style="color: #c7ced8; width: 60%; padding-left: 10px; text-align: left;">View bids below. Coverage will start when a bid has been selected.</p>
      <p v-if="request.bids.length == 0" style="color: #c7ced8; padding-left: 10px; text-align: left;">No bids yet. We are awaiting bids from underwriters. Bids from underwriters usually placed within 2 hours</p>
      <div style="height: calc(100vh - 100px); overflow: scroll">
        <div class="bid" style="position: relative; margin-bottom: 10px" v-for="bid in request.bids" :key="bid._id">
          <table>
            <tr v-if="request.winningBidId && bid.won == 1">
              <td class="key" style="color: white;">This bid has been selected, coverage was activated <i class="fa fa-check-circle"
                  style="color: rgb(26, 160, 104)"></i></td>
              <td class="value"></td>
            </tr>
            <tr>
              <td class="key">Bid Identifier</td>
              <td class="value">{{bid.bidId}}</td>
            </tr>
            <tr>
              <td class="key">Premium</td>
              <td class="value">${{ bid.bidPremium / (1e18) }}</td>
            </tr>
            <tr>
              <td class="key">Underwriter</td>
              <td class="value">{{bid.underwriterAddress}}</td>
            </tr>
            <tr>
              <td class="key">Timestamp</td>
              <td class="value">{{bid.createdAt}}</td>
            </tr>

          </table>
          <a v-on:click="acceptBid(bid)" class="btn btn-primary" v-if="bid.won == 0"
            style="position: absolute; right: 10px; bottom: 10px;">Select Bid</a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import moment from "moment"
import Vue from "vue"
import { mapGetters } from "vuex";
import axios from "axios"
import { Line } from "vue-chartjs/legacy";
import { CoverageContract } from "@/abis/CoverageContract.js"
import { ERC20Contract } from '../abis/ERC20Contract.js'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  PointElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
);

Vue.component("line-chart", {
  extends: Line,
});

export default {
  data() {
    return {
      request: {},
      requestInfo: "",
      acceptBidObject: {},
      acceptBidModalActive: false,
      bidLoading: false,
    }
  },
  components: {

  },
  watch: {
    account() {
      if(this.request.creator) {
        if(this.request.creator.toUpperCase() != this.account.toUpperCase()) {
          window.location.replace(`/request/${this.$route.params.address}`)
        }
      }
    },
    request() {
      // we only want to select the chosen bid when insurance is in progress
      if(this.request.status == "in progress") {
        this.request.bids = this.request.bids.filter(bid => bid.won == 1)
      }
    }
  },
  async mounted() {
    if (this.$route.params.address) {
      // get status
      let res = await axios.get(`https://whispering-reaches-79712.herokuapp.com/api/contracts/address?address=${this.$route.params.address}`)
      console.log(res.data.contract)
      if (res.data.success) {
        if(res.data.contract.creator.toUpperCase() != this.account.toUpperCase()) {
          window.location.replace(`/request/${this.$route.params.address}`)
        }
        this.request = this.cleanupContractData(res.data.contract)
      }
    } else {
      // invalid page
    }
  },
  computed: {
    ...mapGetters({
      getProviderLoggedIn: "getProviderLoggedIn",
      web3store: "getWeb3",
      account: "getAccount",
      balance: "getBalance",
      wrongNetworkMessage: "getWrongNetworkMessage"
    }),
    getFormattedRequestDate() {
      return moment.unix(this.request.metadata.split('|')[3])
    },
    computedChartData() {

      if (this.request.threshold && this.request.limit && this.request.tickSizePayout && this.acceptBidObject.bidPremium) {


        let minnedPayout = (this.acceptBidObject.bidPremium / 1e18) * -1
        let minPayout = (this.acceptBidObject.bidPremium / 1e18) * -1
        let midPayout = (this.acceptBidObject.bidPremium / 1e18) * -1
        let maxPayout = (this.request.limit - this.request.threshold) * this.request.tickSizePayout
        let maxedPayout = (this.request.limit - this.request.threshold) * this.request.tickSizePayout

        let minnedPayoutLabel = this.request.threshold - (2 * (this.request.limit - this.request.threshold))
        let minPayoutLabel = this.request.threshold - (this.request.limit - this.request.threshold)
        let midPayoutLabel = this.request.threshold // hdd = threshold
        let maxPayoutLabel = this.request.limit
        let maxedPayoutLabel = this.request.limit * 2


        return {
          labels: [minnedPayoutLabel, minPayoutLabel, midPayoutLabel, maxPayoutLabel, maxedPayoutLabel],
          datasets: [{
            segment: {
              borderColor: (ctx) => {
                const xVal = ctx.p1.parsed.x;
                if (xVal <= 2) {
                  return '#df0657';
                } else if (xVal <= 10) {
                  return '#24bf73'
                }
              }
            }, borderColor: '#24bf73', pointRadius: 0, showLine: true, data: [minnedPayout, minPayout, midPayout, maxPayout, maxedPayout]
          }]
        }

      } else {
        return {
          labels: [1, 2, 3],
          datasets: [{ borderColor: '#f87979', showLine: true, label: 'Payout / HDD Outcome', data: [10, 20, 30], backgroundColor: '#f87979' }],

        }
      }
    },
    isHomePage: function () {
      return false
    }
  },

  async created() {
  },
  methods: {
    async acceptBid(bid) {
      this.acceptBidModalActive = true;
      this.acceptBidObject = bid
    },
    async takeBid(bid) {
      try {
            this.bidLoading = true;
            let contractAddress = this.$route.params.address
            let contract = await new this.web3store.eth.Contract(CoverageContract, contractAddress);
            let daiContract = await new this.web3store.eth.Contract(ERC20Contract, "0x32307adffe088e383afaa721b06436adaba47dbe" );

            this.$store.commit("setLoadingMessage", "1/2 Approve Spending of Dai - Waiting Approval")
            await daiContract.methods.approve(contractAddress, this.web3store.utils.toWei((bid.bidPremium / 1e18).toString(),'ether')).send({from: this.account, gas: '2000000'}).on('transactionHash', (hash) => {
            this.$store.commit("setLoadingMessage", `2/2 Approving Spending of Dai... (${hash})`)
            }).on('receipt', receipt => {
              this.$store.commit("setLoadingMessage", "")
            }).on('error', (error, receipt) => {
              console.log(error, receipt)
              this.$store.commit("setLoadingMessage", "")
            }) 
            
            this.$store.commit("setLoadingMessage", "2/2 Create Bid - Waiting Approval")
            await contract.methods.takeBid(bid.bidId).send({from: this.account, gas: '2000000'}).on('transactionHash', (hash) => {
            this.$store.commit("setLoadingMessage", `2/2 Taking Bid... (${hash})`)
            }).on('receipt', receipt => {
              console.log(receipt)
              console.log("RECEIVED RECEIPT")
              this.$store.commit("setLoadingMessage", "")
              this.bidLoading = false
              this.acceptBidModalActive = false
              this.$router.go() 
            }).on('error', (error, receipt) => {
              console.log(error)
              this.acceptBidModalActive = false
            }) 

       } catch (e) {
           console.log(e)
           this.$store.commit("setLoadingMessage", "")
           this.bidLoading = false
       }
    },
    cleanupContractData(contract) {
      console.log("contract", contract)
      delete contract._id;
      delete contract._v;
      delete contract.updatedAt;
      delete contract.__v;
      delete contract.createdAt;
      if (contract.coverageType == 1) {
        contract.coverageType = "HDD Call";
        contract.collateral =
          (contract.limit - contract.threshold) * contract.tickSizePayout;
      } else {
        contract.coverageType = "HDD Put";
        contract.collateral =
          (contract.threshold - contract.limit) * contract.tickSizePayout;
      }
      if (contract.status == 0) contract.status = "open";
      if (contract.status == 1) contract.status = "in progress";
      if (contract.status == 2) contract.status = "oracle requested";
      if (contract.status == 3) contract.status = "settled";
      if (contract.status == 5) contract.status = "cancelled";

      if (contract.status == 0) contract.action = "create offer";
      if (contract.status != 0) contract.action = "view details"
      return contract;
    }
  }
}
</script>

<style scoped lang="scss">
  // spinner 
.loader-mini,
.loader-mini:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}
.loader-mini {
  margin: 0px auto;
  margin-top: 1px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid #171721;
  border-right: 0.5em solid #171721;
  border-bottom: 0.5em solid #171721;
  border-left: 0.5em solid white;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.backdrop {
  backdrop-filter: blur(8px);
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mdl {
  z-index: 4;
  position: fixed;
  width: 500px !important;
  top: 60px;
  left: calc(50% - 250px) !important;
  background-color: white !important;
  border-radius: 5px;

  &.bid {
    h2 {
      text-align: left;
      font-size: 16px;
      color: black;
    }

    h3 {
      width: 100%;
      text-align: left;
      font-size: 15px;
      color: black;
    }

    input {
      float: left;
      width: 95%;
    }
  }
}


.sidebar {
  height: calc(100vh - 70px) !important;
  overflow: scroll !important;
  background-color: #1c1d27;
  float: left;
  // min-height: 100vh;
  padding: 0;
}

.maincontent {
  float: left;
  padding: 20px;
}

.title {
  color: white;
  font-weight: 600;
  padding-left: 10px;
  text-align: left;
  font-size: 17px;
}

.subtitle {
  color: #c7ced8;
  font-weight: 400;
  padding-left: 10px;
  text-align: left;
  font-size: 15px;
}

.bid {
  padding: 20px;
  width: unset;
  color: #c7ced8;
  background-color: #1c1d27;
  border-radius: 2px;
}

table {
  tr {
    td {
      text-align: left;
      padding-right: 50px;

      &.key {
        font-weight: 500;
      }
    }
  }
}
</style>