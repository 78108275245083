<template>
  <div id="app">
    <!-- header -->
    <Header v-if="!isHomePage"></Header>
    <div class="net-banner" style="text-align: left;">Vulcan Optimism Goerli - Alpha 0.22</div>
    <div v-if="wrongNetworkMessage" class="err-banner">{{wrongNetworkMessage}}</div>
     <div class="loading-footer" v-if="loadingMessage && loadingMessage.length > 0">
    <div class="container">
      <div class="lds-ring" style="float: left;"><div></div><div></div><div></div><div></div></div><p> {{loadingMessage}}</p>
    </div>
  </div>
    <router-view></router-view>
    <!-- footer -->
  </div>
</template>

<script>

import Header from './components/Header.vue'
import { mapGetters } from "vuex";

export default {
  name: 'App',
  components: {
    Header
  },
   watch: {
    account() {
      this.$store.dispatch("getBalance", this.account);
    }
  },
  mounted() {


  },
  computed: {
     ...mapGetters({
      getProviderLoggedIn: "getProviderLoggedIn",
      web3store: "getWeb3",
      loadingMessage: "getLoadingMessage",
      account: "getAccount",
      balance: "getBalance",
      wrongNetworkMessage: "getWrongNetworkMessage"
    }),
    isHomePage: function()  {
      if(this.$route.path == "/") return true
      else {
        return false
      }
    }
  },

  async created() {
  },
  methods: {

  }
}
</script>

<style lang="scss">
  

input.date-input {
  width: 100%;
    border-radius: 5px;
    border: none;
    height: 37px;
    padding: 10px;
    background-color: #24263b;
  color: white;
  border: 1px solid white;
    

}

div.err-banner {
  background-color: red;
  color: white;
  font-weight: 500;
  padding: 7px;
  padding-right: 50px;
  padding-left: 50px;
  width: 100%;
}

div.net-banner {
  background-color: #460183;
  color: white;
  font-weight: 500;
  padding: 7px;
  padding-right: 50px;
  padding-left: 30px;
  width: 100%;
}

#app {
  background-color: #14141C;
  min-height: 100vh;
  overflow: scroll!important;
font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.loading-footer {
  z-index: 99;
  background-color: #2d3056;
  font-weight: 600;
  color: white;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 10px;
  p {
    padding-top: 12px;
    text-align: left;
    margin-left: 65px;
    font-size: 16px;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>


