<template>
    <div class="row clearfix">
      <div class="col-md-3 sidebar clearfix">
        <!-- create request -->
        <form action="" v-if="requestStep == 0">


           <!-- <h3 class="subtitle" style="font-weight: 500; font-size: 18px;">Insurance Properties</h3> -->
           <p class="label"><i style="margin-right: 5px; margin-top: 15px;" class="fa fa-temperature-low"></i> HDD Call</p>
           <p class="product">Contract pays for each HDD above threshold. HDD is accumulated when the average daily temperature is below 65 degrees fahrenheit.</p>
          <hr/>
          <p class="label"><i style="margin-right: 5px;" class="fa fa-compass"></i> Location</p>
          <input v-if="!currentPosition.formatted" type="text" class="form-control" placeholder="select location on the map" >
          <input v-if="currentPosition.formatted" v-model="currentPosition.formatted" type="text" class="form-control" placeholder="select location on the map" >

          <!-- <p class="label" v-if="coordinateFormatted">Coordinates </p>
          <input v-if="coordinateFormatted" v-model="coordinateFormatted" type="text" class="form-control" placeholder="select location on the map" disabled> -->
          <hr/>
          <p class="label"><i style="margin-right: 5px;" class="fa fa-clock"></i>Contract Start</p>
          <datepicker v-model="date" :disabled-dates="disabledDates" placeholder="choose a start date" :input-class="'date-input'"></datepicker>

            <hr/>
          <p class="label" style="text-align: left; margin-top: 20px; color: white;"><i style="margin-right: 5px;" class="fa fa-clock"> </i>Contract Term: {{days}} days</p>
          <div class="slidecontainer" style="width: 100%; margin-top: 20px; margin-bottom: 0px;">
            <input style="width: 100%;" v-model="days" type="range" min="1" max="90" class="slider" id="myRange">
          </div>
          
          <hr style="margin-top: 5px;"/>

          <p class="label">Threshold</p>
          <small class="small-info">HDDs above this threshold will be paid for.</small>
          <input v-model="threshold" type="text" class="form-control" :placeholder="hdd ? hdd : 'threshold'">

          <p class="label">Upper Limit</p>
          <small class="small-info">Upper boundary of the coverage</small>
          <input v-model="limit" :min="threshold" type="text" class="form-control " :placeholder="hdd ? hdd + 20 : 'limit'">



          <p class="label">Payout per HDD in USD</p>
          <input type="text" v-model="payout" class="form-control" placeholder="payout in USD per HDD">

        </form>

        <form action="" v-if="requestStep == 1">
          <p class="label"><i class="fa fa-file-invoice" style="margin-right: 10px;"></i> Review Insurance Request</p>

          <table class="table">
            <tr>
              <td class="key">treshold</td>
              <td class="value">{{threshold}}</td>
            </tr>
            <tr>
              <td class="key">payout</td>
              <td class="value"> {{payout}}</td>
            </tr>
            <tr>
              <td class="key">limit</td>
              <td class="value">{{limit}}</td>
            </tr>
            <tr>
              <td class="key">days</td>
              <td class="value">{{days}}</td>
            </tr>
            <tr>
              <td class="key">location</td>
              <td class="value">{{coordinateFormatted}}</td>
            </tr>

          </table>



          <p style="color: white; margin-top: 20px;">You will get paid {{payout}}$ per HDD for each HDD above {{threshold}}. Maximum payout 
            is {{(limit - threshold) * payout}}$ at {{limit}} HDD. 
          </p>
          
          <div v-if="!loading.insuranceRequest && !insuranceRequestDone">
            <a class="btn btn-primary" v-if="!account" @click="connectWallet()">Connect Wallet</a>
            <a class="btn btn-primary disabled" v-if="!account">Connect wallet to confirm</a>
            <a class="btn btn-primary disabled" v-if="wrongNetworkMessage">Connect to correct network to confirm</a>
            <a class="btn btn-primary" v-if="account && !wrongNetworkMessage" @click="createInsuranceRequest()">Confirm Insurance Request</a>
            <a class="btn btn-primary secondary" @click="requestStep = 0">Edit Insurance Request</a>
          </div>

          <div v-if="loading.insuranceRequest && !insuranceRequestDone">
            <p style="color: white; font-weight: 500; margin-top: 20px;">Interacting with the blockchain, please wait..</p>
            <div class="loader-mini" style="margin-top: 5px;"></div>
          </div>
        </form>

      </div>

      <div class="col-md-3" style="position: relative; background-color: #252634; padding: 30px;" v-if="threshold && limit && payout">
        <p class="label"><i class="fas fa-info-circle" style="margin-right: 10px;"></i> Payout Model</p>
        <small class="small-info" style="margin-bottom: 10px;"><strong>Note: using estimation premium. Final premium is set by underwriters.</strong></small>
        <br></br>
        <small class="small-info">If the premium is ${{((limit - threshold) * payout) / 4}} the model would look like below.  Maximum loss is equal to the offer of your premium. Maximum payout is <strong>${{((limit - threshold) * payout)}}. 

        </strong></small>
        <line-chart v-if="computedChartData"
            :chart-options="{
              plugins: {  
              legend: {
                display: false,
              labels: {
              color: 'white',
              }
            }
            }}" :chart-data="computedChartData"
          />

          <p class="label" style="margin-top: 30px;"><i class="fas fa-file-signature" style="margin-right: 10px;"></i> Optimize for offers</p>
          
          <div style="padding: 10px; margin-top: 10px">
            <div style="text-align: left;" >
            <p v-if="payout < 25" style="color: white; font-size: 14px;"><i class="fa fa-check-circle" style="margin-right: 20px; color: #24bf73;"></i>  Payout per HDD lower than 25</p>
          </div>
          <div style="text-align: left;">
            <p v-if="payout > 24" style="color: white; font-size: 14px;"><i class="fa fa-times" style="margin-right: 20px; color: white;"></i>  Payout per HDD higher than 24</p>
          </div>

          <div style="text-align: left;">
            <p style="font-size: 14px; color: white" v-if="(((limit - threshold) / threshold) * 100).toFixed(2) < 20.01"><i class="fa fa-check-circle" style="margin-right: 20px; color: #24bf73;"></i> Upper Limit is {{(((limit - threshold) / threshold) * 100).toFixed(2)}}% above threshold  </p>
          </div>


          <div style="text-align: left;">
            <p style="font-size: 14px; color: white" v-if="(((limit - threshold) / threshold) * 100).toFixed(2) > 20.01"><i class="fa fa-times" style="margin-right: 20px; color: white;"></i> Upper Limit is {{(((limit - threshold) / threshold) * 100).toFixed(2)}}% above threshold (recommend keeping it within 20%)  </p>
          </div>
          </div>

          <div style="position: absolute; width: 100%; bottom: 0; left: 0; padding: 20px; margin-bottom: 23px">
            <a v-if="!limit || !date || !threshold || !payout || !currentPosition.formatted" class="btn btn-primary disabled" @click="requestInsurance()">Request Insurance</a>
            <a v-if="limit && date && threshold && payout && currentPosition.formatted" class="btn btn-primary" @click="requestInsurance()">Request Insurance</a>
          </div>


      </div>





      <div style="position: absolute; height: 100%; right: 0; top: 0; z-index: 100; width: 76%; background: none:" v-if="requestStep == 1">

      </div>

      <div class="clearfix mapbox" :class="{'col-md-6' : (threshold && limit && payout), 'col-md-9': (!threshold || !limit || !payout)}">
        <MapView name="example" style="margin-top: -10px!important;"></MapView>
      </div>
      <div class="overlay" v-if="hdd != null || loading.hdd">
       <p style="margin-bottom: -5px!important;">HDD Last Year <i style="margin-left: 10px" class="fa fa-info-circle"></i> </p>
       <small>{{days}}d lat: {{currentPosition.position.lat.toFixed(2)}} lon: {{currentPosition.position.long.toFixed(2)}} </small>
       <h3 v-if="!loading.hdd"> {{hdd}} HDD</h3>
       <h3 v-if="loading.hdd"> <div class="loader-mini" style="margin-top: 5px;"></div></h3>
      </div>
    </div>
</template>

<script>

import moment from "moment"
import Datepicker from 'vuejs-datepicker';
import Vue from "vue"
import { mapGetters } from 'vuex' 
import { Line } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  PointElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
);

Vue.component("line-chart", {
  extends: Line,
});

import { CoverageFactory } from '../abis/CoverageFactory.js'
import axios from "axios"
import MapView from "../components/MapView.vue";
export default {
  name: 'home',
  data() {
    return { 
      days: 7,
      date: "",
      hdd: null,
      threshold: null,
      limit: null,
      payout: null,
      requestStep: 0,
      insuranceRequestDone: false,
      loading: {
        hdd: false,
        insuranceRequest: false
      },
      disabledDates: {
        to: new Date(moment().format('YYYY'), moment().format('MM') -1, moment().format('D'))
      }

      

    }
  },
  watch: {
    currentPosition(pos) {
      this.getHistoricalData(pos.position)
    },
    days(newDays, oldDays) {
      this.oldValues = oldDays;
        if (this.timer) {
          // Remove timer if value changes again
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.timer = null;
          if (oldDays == this.oldValues) {
            this.getHistoricalData(this.currentPosition.position)
          }
      }, 700)
    }
  },
  computed: {
    computedChartData() {
      if(this.threshold && this.limit && this.payout) {
        
        let maxPayout = (this.limit - this.threshold) * this.payout 
        let maxedPayout = (this.limit - this.threshold) * this.payout 

        let midPayout = (maxPayout / 4) * -1
        let minnedPayout = (maxPayout / 4) * -1
        let minPayout =  (maxPayout / 4) * -1


        let minnedPayoutLabel = this.threshold - (2 * (this.limit - this.threshold))
        let minPayoutLabel = this.threshold - (this.limit - this.threshold)
        let midPayoutLabel = this.threshold // hdd = threshold
        let maxPayoutLabel = this.limit 
        let maxedPayoutLabel = this.limit * 2


        return {
          labels: [minnedPayoutLabel, minPayoutLabel, midPayoutLabel, maxPayoutLabel, maxedPayoutLabel],
          datasets: [ { segment: {
          borderColor: (ctx) => {
          const xVal = ctx.p1.parsed.x;
          if (xVal <= 2) {
            return '#df0657';
          } else if (xVal <= 10) {
            return '#24bf73'
          } 
        }},borderColor: '#24bf73', pointRadius: 0, showLine: true,  data: [minnedPayout, minPayout, midPayout, maxPayout, maxedPayout ] } ]
       }

      } else {
        return {
          labels: [1, 2, 3],
          datasets: [ {borderColor: '#f87979', showLine: true, label: 'Payout / HDD Outcome', data: [10, 20, 30], backgroundColor: '#f87979'}],
          
        }
      }
    },
     ...mapGetters({
      currentPosition: "getCurrentPosition",
      web3store: "getWeb3",
      account: "getAccount",
      wrongNetworkMessage: "getWrongNetworkMessage"
    }),
    coordinateFormatted() {
      if(this.currentPosition.position) return "lat: " + this.currentPosition.position.lat.toFixed(3) + " lon: " + this.currentPosition.position.long.toFixed(3)
      else return ""
    }
  },
  methods: {
    async createInsuranceRequest() {
      this.loading.insuranceRequest = true
      let factoryContract = await new this.web3store.eth.Contract(CoverageFactory, "0x846ffb64637089aBc5C9F03d8F5852AD3C441923");   

      let expirationCoverageRequest = await this.web3store.eth.getBlockNumber()
      expirationCoverageRequest += 3000;

      
      let dateEnd = parseInt(moment(this.date).utc().valueOf() / 1000) + parseInt((this.days * 86400))
      console.log(dateEnd)
          console.log( 1, 
          1, 
          parseInt(this.threshold), 
          parseInt(this.payout), 
          parseInt(this.limit),
          `${this.currentPosition.position.lat}|${this.currentPosition.position.long}|${this.days}|${dateEnd}`)

       let res = await factoryContract.methods.createCoverageContract(expirationCoverageRequest, 
          1, 
          1, 
          parseInt(this.threshold), 
          parseInt(this.payout), 
          parseInt(this.limit),
          `${this.currentPosition.position.lat}|${this.currentPosition.position.long}|${this.days}|${dateEnd}`).send({from: this.account, gas: '2000000'}) 


      
      let coverageContractAddress = res.events.coverageContractCreated.returnValues.coverageContract
      console.log(coverageContractAddress)
      this.loading.insuranceRequest = false
      this.insuranceRequestDone = true
      window.location.replace(`/myrequest/${coverageContractAddress}`)
    },
    connectWallet() {
      this.$store.commit("connectWallet")
    },
    async requestInsurance() {
      this.requestStep = 1; 
    },
    async getHistoricalData(position) {
      // current timestamp + 30 days - 1 year
      
      let dateEnd;
      if(this.date) {
        dateEnd = this.date + parseInt((this.days * 86400000)) - parseInt(340 * 86400000)
      } else {
        dateEnd = parseInt(Date.now()) + parseInt((this.days * 86400000)) - parseInt(340 * 86400000)
      }
      
      console.log(dateEnd)
      this.loading.hdd = true
      this.hdd = 0
      const url = "https://whispering-reaches-79712.herokuapp.com/api/histdata/hcdd"
      const request_payload = `${position.lat}|${position.long}|${this.days}|${parseInt(dateEnd / 1000)}`
      let res = await axios.post(url, {request_payload})
      console.log(res.data.data.data.hdd, "data")

      if(res.data) this.hdd = res.data.data.data.hdd
      else this.hdd = null
      this.loading.hdd = false
    }
  },
  mounted() {
    console.log(this.disabledDates)
  },
  components: {
    MapView,
    Datepicker
  },
  props: {
    msg: String
  }
}
</script>


<style scoped lang="scss">

  
.form {
  padding: 10px;
  text-align: left;
}


.input-text {

}

input:focus {
  // background-color: #1c1c27;
  background-color: #24263b;
  color: white;
}

input {
  // background-color: #1c1c27;
  background-color: #24263b;
  color: white;
}
.sidebar {
  background-color: #1c1d27;
  float: left;
    -webkit-box-shadow: 10px 10px 5px -5px rgba(0,0,0,0.21);
-moz-box-shadow: 10px 10px 5px -5px rgba(0,0,0,0.21);
  box-shadow: 10px 10px 5px -5px rgba(0,0,0,0.21);
  position: relative;

}

.small-info {
      color: white;
    text-align: left!important;
    float: left;
    margin-bottom: 10px;
    margin-top: -10px;
}

.btn-primary {
  background-color: #0275ff;
  float: left;
  width: 100%!important;
  margin-top: 15px;
  padding: 10px;
  color: white;
  border: none;
  width: 250px;
  &.secondary {
    background-color: #424456;
    color: white;
  }
}

p.label {
  color: white;
  text-align: left;
  margin-bottom: 12px;
  font-weight: 500;
}
input {
  margin-bottom: 18px;
}


.product {
  text-align: left;
  font-size: 14px;
  color: white;
}

.mapbox {
  padding-top: 15px;
  float: left;
  padding: 0!important;

}

.overlay {
  position: absolute; 
  right: 15px;
  top: 80px;
  z-index: 100;
  width: 200px; 
  background-color: #252634;
  color: white;
  border-radius: 10px;
  height: 100px; 
  padding: 10px;
  p {
    margin-bottom: 0;
  }
  h3 {
    margin-bottom: 0;
    margin-top: 3px;
  }
}

h3.subtitle {
  color: white;
  text-align: left;
  font-size: 19px;
  padding-left: 0;
  margin-top: 13px;
  font-weight: 300;
}


.table {
  color:#444;
  padding: 5px!important; 
  border-radius: 5px;
  border-collapse:separate; 
  border-spacing:0.5em;
  background-color: white;


  .key {
    text-align: left;
  }
  .value {
    text-align: center;
    border-radius: 5px;
    background-color: #E7E7E7;
  }
}
</style>